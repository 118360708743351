import React, { ReactElement } from 'react';
import './header.scss';

interface HeaderProps {
  text: string;
}

export default function Header({ text }: HeaderProps): ReactElement {
  return <h2 className='header'>{text}</h2>;
}
