import Dictionary from '../../../types/dictionary';
import Experience from '../../../types/experience';
import { flattenCompanyData } from '../models/company';
import { flattenTaskData } from '../models/task';

export const flattenExperienceData = (
  experienceData: Dictionary<any>
): Experience => {
  return {
    ...experienceData,
    company: flattenCompanyData(experienceData.company.fields),
    tasks: experienceData.tasks.map((task: Dictionary<any>) => {
      return flattenTaskData(task.fields);
    }),
  } as Experience;
};
