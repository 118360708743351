import React, { ReactElement } from 'react';
import Experience from '../../types/experience';
import ListItem from '../list-item/list-item';
import Header from '../header/header';
import { dateToMonthYear } from '../../helpers/date-format';
import './experience-list.scss';

interface ExperienceListProps {
  headingText: string;
  experiences: Experience[];
}

export default function ExperienceList({
  headingText,
  experiences,
}: ExperienceListProps): ReactElement {
  return (
    <section className='experiences list'>
      <Header text={headingText} />
      <ul className='items'>
        {experiences.map((experience, key) => {
          const startDate = dateToMonthYear(new Date(experience.startDate));
          const endDate = experience.endDate
            ? dateToMonthYear(new Date(experience.endDate))
            : 'Present';
          return (
            <li key={key}>
              <ListItem
                key={key}
                image={experience.company.logo}
                heading={experience.position}
                subHeading={experience.company.name}
                infoHeading={`${startDate} - ${endDate}`}
                details={experience.tasks.map((task) => task.description)}
              />
            </li>
          );
        })}
      </ul>
    </section>
  );
}
