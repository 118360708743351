import Dictionary from '../../../types/dictionary';
import Certificate from '../../../types/certificate';
import { flattenOrganizationData } from './organization';

export const flattenCertificateData = (
  certificateData: Dictionary<any>
): Certificate => {
  return {
    ...certificateData,
    organization: flattenOrganizationData(certificateData.organization.fields),
  } as Certificate;
};
