import React, { ReactElement } from 'react';
import './icon-item-list.scss';

interface IconItemListProps {
  items: IconItem[];
  defaultIcon: string;
}

interface IconItem {
  name: string;
  icon?: string;
  isBrandIcon: boolean;
  logo?: string;
}

export default function IconItemList({
  items,
  defaultIcon,
}: IconItemListProps): ReactElement {
  const length = items.length;

  // initial order values for left, right column
  let rightColumnStart = (length % 2 === 0 ? length : length + 1) / 2;
  let leftColumnStart = 0;

  // since the items will be displayed in 2 columns, it needs to be rearranged
  // to appear how it was sorted, being the first half of the array should be
  // in the first column, and the other half in the 2nd.
  // since the `ul` is not in fixed width, it displays the items in alternate
  // (1st item goes to 1st column, 2nd item goes to the 2nd column)
  let sortedItems: IconItem[] = [];
  const first = items.slice(0, rightColumnStart);
  const second = items.slice(rightColumnStart);
  items.forEach((item, index) => {
    const source = index % 2 ? second : first;
    sortedItems.push(source.shift() as IconItem);
  });

  return (
    <ul className='icon-item-list'>
      {sortedItems.map(
        ({ icon = defaultIcon, isBrandIcon, logo, name }, index) => {
          // determine order
          let current = 0;
          if (index % 2 !== 0) {
            // right column
            rightColumnStart += 1;
            current = rightColumnStart;
          } else {
            leftColumnStart += 1;
            current = leftColumnStart;
          }

          // common attributes for both icon and logo
          const attributes = {
            key: index,
            style: { order: current },
          };

          // render the logo, if set, as the item icon
          if (logo) {
            return (
              <li {...attributes} className='logo'>
                <figure>
                  <img src={logo} alt={name} />
                </figure>
                <span>{name}</span>
              </li>
            );
          }

          // else, use favicon
          // since react does not like rendering unicode characters directly,
          // we need to do this for it to render the fontawesome icon properly
          const unicode = JSON.parse(`["\\u${icon}"]`)[0];

          return (
            <li
              {...attributes}
              className={`icon item${isBrandIcon ? ' brand' : ''}`}
              data-icon={unicode}
            >
              {name}
            </li>
          );
        }
      )}
    </ul>
  );
}
