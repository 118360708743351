import Dictionary from '../../../types/dictionary';
import Organization from '../../../types/organization';

export const flattenOrganizationData = (
  organizationData: Dictionary<any>
): Organization => {
  return {
    ...organizationData,
    logo: `https:${organizationData.logo.fields.file.url}`,
  } as Organization;
};
