import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './list-item.scss';

interface ListItemProps {
  key: number;
  image?: string;
  heading: string;
  subHeading: string;
  infoHeading: string;
  details?: string[];
}

export default function ListItem({
  image,
  heading,
  subHeading,
  infoHeading,
  details = [],
}: ListItemProps): ReactElement {
  return (
    <section className='list-item'>
      <Container className='p-0 m-0'>
        <Row className='m-0'>
          <Col className='heading p-0'>
            {image && (
              <figure className='logo'>
                <img src={image} alt='' />
              </figure>
            )}
            <div>
              <h3>{heading}</h3>
              <h4>{subHeading}</h4>
              <h5>{infoHeading}</h5>
            </div>
          </Col>
          <Col className='body p-0'>
            {details.length > 0 && (
              <ul className='details'>
                {details.map((detail, key) => {
                  return <li key={key}>{detail}</li>;
                })}
              </ul>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
}
