import React, { ReactElement } from 'react';
import Header from '../header/header';
import Skill from '../../types/skill';
import IconItemList from '../icon-item-list/icon-item-list';

import './skill-list.scss';

interface SkillListProps {
  headingText: string;
  skills: Skill[];
}

export default function SkillList({
  headingText,
  skills,
}: SkillListProps): ReactElement {
  return (
    <section className='skills'>
      <Header text={headingText} />
      <IconItemList items={skills} defaultIcon='f085' />
    </section>
  );
}
