import Dictionary from '../../../types/dictionary';
import Knowledge from '../../../types/knowledge';

// @TODO - shared functionality with `Skill`. abstract this method?
export const flattenKnowledgeData = (
  knowledgeData: Dictionary<any>
): Knowledge => {
  return {
    name: knowledgeData.name,
    icon: knowledgeData.fontawesomeIcon,
    isBrandIcon: knowledgeData.isBrandIcon,
  } as Knowledge;
};
