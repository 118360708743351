import React from 'react';
import ReactDOM from 'react-dom';
import Homepage from './pages/homepage/homepage';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.StrictMode>
    <link
      rel='stylesheet'
      href='https://use.fontawesome.com/releases/v5.13.0/css/all.css'
    ></link>
    <Homepage></Homepage>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
