import React, { ReactElement } from 'react';
import Certificate from '../../types/certificate';
import ListItem from '../list-item/list-item';
import Header from '../header/header';
import './certificate-list.scss';

interface CertificateListProps {
  headingText: string;
  certificates: Certificate[];
}

export default function CertificateList({
  headingText,
  certificates,
}: CertificateListProps): ReactElement {
  return (
    <section className='certificates list'>
      <Header text={headingText} />
      <ul className='items'>
        {certificates.map((certificate, key) => {
          const listItem = (
            <ListItem
              key={key}
              image={certificate.organization.logo}
              heading={certificate.name}
              subHeading={certificate.organization.name}
              // @TODO - for some odd reason, complier does not like the format being saved in a variable
              // doing this for now
              infoHeading={`${new Date(certificate.dateIssued).toLocaleString(
                'en-US',
                {
                  month: 'long',
                  year: 'numeric',
                }
              )}`}
            />
          );

          // @TODO - add feedback when hovering a link? for non-links?
          return (
            <li key={key}>
              {certificate.link ? (
                <a
                  href={certificate.link}
                  target='_blank'
                  rel='noreferrer'
                  data-toggle='tooltip'
                  title='View Certificate'
                >
                  {listItem}
                </a>
              ) : (
                listItem
              )}
            </li>
          );
        })}
      </ul>
    </section>
  );
}
