import React, { ReactElement, useEffect, useState } from 'react';
import Profile from '../../components/profile/profile';
import ExperienceList from '../../components/experience-list/experience-list';
import KnowledgeList from '../../components/knowledge-list/knowledge-list';
import SkillList from '../../components/skill-list/skill-list';
import CertificateList from '../../components/certificate-list/certificate-list';
import AchievementList from '../../components/achievement-list/achievement-list';
import ReferenceList from '../../components/reference-list/reference-list';
import Person from '../../types/person';
import { getPerson } from '../../adapters/contentful/models/person';
import './homepage.scss';
import { Col, Container, Row, Spinner } from 'react-bootstrap';

export default function Homepage(): ReactElement {
  const [person, setPerson] = useState({} as Person);
  const [loading, setLoading] = useState(true); // @TODO - handle loading of images as well

  useEffect(() => {
    const fetchData = async () => {
      const result = await getPerson(
        process.env.REACT_APP_CONTENTFUL_PERSON_ENTRY_ID as string
      );
      setPerson(result);
      setLoading(false);
    };
    fetchData();
  }, []);
  return (
    <>
      {loading ? (
        <div className='loading'>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
          <span>Loading...</span>
        </div>
      ) : (
        <Container fluid='lg' className='homepage'>
          <Row className='main'>
            <Col className='column p-0' xs={10} sm={10} md={9} lg={true}>
              <Profile person={person ?? {}} />
            </Col>
          </Row>
          <Row className='content'>
            <Col className='column' xs={10} sm={10} md={9} lg={true}>
              <ExperienceList
                headingText='Experiences'
                experiences={person?.experiences ?? []}
              />
              <AchievementList
                headingText='Achievements'
                achievements={person?.achievements ?? []}
              />
            </Col>
            <Col className='column' xs={10} sm={10} md={9} lg={true}>
              <KnowledgeList
                headingText='Industry Knowledge'
                knowledges={person?.knowledges ?? []}
              />
              <SkillList
                headingText='Technical Skills'
                skills={person?.skills ?? []}
              />
              <CertificateList
                headingText='Certificates'
                certificates={person?.certificates ?? []}
              />
              <ReferenceList
                headingText='References'
                references={person?.references ?? []}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
