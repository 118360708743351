import client from '../client';
import Person from '../../../types/person';
import Dictionary from '../../../types/dictionary';
import { flattenExperienceData } from '../models/experience';
import { flattenKnowledgeData } from '../models/knowledge';
import { flattenSkillData } from '../models/skill';
import { flattenCertificateData } from '../models/certificate';
import { flattenAchievementData } from '../models/achievement';
import { flattenReferenceData } from '../models/reference';

const CONTENT_TYPE = 'person';

export const getPerson = async (id: string): Promise<Person> => {
  const result = await client.getEntries({
    content_type: CONTENT_TYPE,
    include: 3,
    'sys.id': id,
  });
  if (result.items.length === 0) {
    return {} as Person;
  }
  return parsePersonData(result.items[0].fields as Dictionary<any>);
};

const parsePersonData = (personData: Dictionary<any>) => {
  const experiences = personData.experiences ?? [];
  const knowledges = personData.knowledges ?? [];
  const skills = personData.skills ?? [];
  const certificates = personData.certificates ?? [];
  const achievements = personData.achievements ?? [];
  const references = personData.references ?? [];
  return {
    ...personData,
    profilePicture: `https:${personData.profilePicture.fields.file.url}`,
    experiences: experiences.map((experience: Dictionary<any>) => {
      return flattenExperienceData(experience.fields);
    }),
    knowledges: knowledges.map((knowledge: Dictionary<any>) => {
      return flattenKnowledgeData(knowledge.fields);
    }),
    skills: skills.map((skill: Dictionary<any>) => {
      return flattenSkillData(skill.fields);
    }),
    certificates: certificates.map((certificate: Dictionary<any>) => {
      return flattenCertificateData(certificate.fields);
    }),
    achievements: achievements.map((achievement: Dictionary<any>) => {
      return flattenAchievementData(achievement.fields);
    }),
    references: references.map((reference: Dictionary<any>) => {
      return flattenReferenceData(reference.fields);
    }),
  } as Person;
};
