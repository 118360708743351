import Dictionary from '../../../types/dictionary';
import Reference from '../../../types/reference';
import { flattenCompanyData } from './company';

export const flattenReferenceData = (
  referenceData: Dictionary<any>
): Reference => {
  return {
    fullName: referenceData.fullName,
    title: referenceData.title,
    company: flattenCompanyData(referenceData.company.fields),
    phoneNumber: referenceData.phoneNumber,
    emailAddress: referenceData.emailAddress,
  } as Reference;
};
