import Dictionary from '../../../types/dictionary';
import Achievement from '../../../types/achievement';
import { flattenOrganizationData } from './organization';
import { flattenTaskData } from './task';

export const flattenAchievementData = (
  achievementData: Dictionary<any>
): Achievement => {
  const tasks = achievementData?.tasks ?? [];
  return {
    ...achievementData,
    organization: flattenOrganizationData(achievementData.organization.fields),
    tasks: tasks.map((task: Dictionary<any>) => {
      return flattenTaskData(task.fields);
    }),
  } as Achievement;
};
