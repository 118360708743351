import Dictionary from '../../../types/dictionary';
import Skill from '../../../types/skill';

export const flattenSkillData = (skillData: Dictionary<any>): Skill => {
  return {
    name: skillData.name,
    icon: skillData.fontawesomeIcon,
    isBrandIcon: skillData.isBrandIcon,
    logo: skillData.logo ? `https:${skillData.logo.fields.file.url}` : '',
  } as Skill;
};
