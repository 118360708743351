import React, { ReactElement } from 'react';
import Achievement from '../../types/achievement';
import ListItem from '../list-item/list-item';
import Header from '../header/header';
import './achievement-list.scss';

interface AchievementListProps {
  headingText: string;
  achievements: Achievement[];
}

export default function AchievementList({
  headingText,
  achievements,
}: AchievementListProps): ReactElement {
  return (
    <section className='achievements list'>
      <Header text={headingText} />
      <ul className='items'>
        {achievements.map((achievement, key) => {
          const listItem = (
            <ListItem
              key={key}
              image={achievement.organization.logo}
              heading={achievement.name}
              subHeading={achievement.organization.name}
              // @TODO - for some odd reason, complier does not like the format being saved in a variable
              // doing this for now
              infoHeading={`${new Date(achievement.date).toLocaleString(
                'en-US',
                {
                  month: 'long',
                  year: 'numeric',
                }
              )}`}
              details={achievement.tasks.map((task) => task.description)}
            />
          );

          // @TODO - add feedback when hovering a link? for non-links?
          return <li key={key}>{listItem}</li>;
        })}
      </ul>
    </section>
  );
}
