import React, { ReactElement } from 'react';
import Header from '../header/header';
import Knowledge from '../../types/knowledge';
import IconItemList from '../icon-item-list/icon-item-list';

import './knowledge-list.scss';

interface KnowledgeListProps {
  headingText: string;
  knowledges: Knowledge[];
}

export default function KnowledgeList({
  headingText,
  knowledges,
}: KnowledgeListProps): ReactElement {
  return (
    <section className='knowledges'>
      <Header text={headingText} />
      <IconItemList items={knowledges} defaultIcon='f085' />
    </section>
  );
}
