import React, { ReactElement } from 'react';
import Reference from '../../types/reference';
import Header from '../header/header';
import './reference-list.scss';

interface ReferenceListProps {
  headingText: string;
  references: Reference[];
}

export default function ReferenceList({
  headingText,
  references,
}: ReferenceListProps): ReactElement {
  return (
    <section className='references list'>
      <Header text={headingText} />
      <ul className='items'>
        {references.map((reference, key) => {
          return (
            <li key={key}>
              <h3>{reference.fullName}</h3>
              <h4>{reference.title}</h4>
              <h5>{reference.company?.name}</h5>
              <p>{reference.emailAddress}</p>
            </li>
          );
        })}
      </ul>
    </section>
  );
}
