import React, { ReactElement } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Person from '../../types/person';
import './profile.scss';

interface ProfileProps {
  person: Person;
}

export default function Profile({ person }: ProfileProps): ReactElement {
  return (
    <Container fluid className='profile'>
      <Row>
        <Col className='image' sm={12} md={12} lg={4} xl={4}>
          <figure>
            <img src={person.profilePicture} alt='' />
          </figure>
        </Col>
        <Col
          className='info text-center text-lg-left text-xl-left'
          sm={12}
          md={12}
          lg={8}
          xl={8}
        >
          <header>
            <h1>{person.fullName}</h1>
          </header>
          <h2 className='title'>{person.title}</h2>
          <p className='about'>{person.about}</p>
          <ul className='details text-left'>
            <li className='icon email'>{person.emailAddress}</li>
            <li className='icon location'>{person.location}</li>
            <li className='icon degree'>
              <div>
                <span>{person.collegeDegree}</span>
                <p>{person.school}</p>
              </div>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}
